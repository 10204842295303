.registerContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.registerContainer-left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 40vw;
    height: 100vh;
}

.registerContent {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    height: 60vh;
    width: 30vw;
}

.registerContainer-right {
    width: 60vw;
    height: 100vh;
}

.title {
    font-size: 48px;
    font-weight: bold;
}

.password {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 30vw;
}

.forget-password {
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.button {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: bold;
    background-color: #7F976C;
    border-radius: 50px;
    border: none;
    outline: none;
    width: 200px;
    height: 50px;
}

.button:hover {
    background-color: #8EA37E;
    cursor: pointer;
}

.button:active {
    background-color: #8EA37E;
}

.cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.cta span {
    color: #7F976C;
}

.picture {
    width: 60vw;
    height: 100vh;
}

@media screen and (max-width: 1200px) {
    .registerContainer-left {
        width: 100vw;
    }

    .registerContent {
        width: 60vw;
        max-width: 500px;
    }

    .password {
        width: 60vw;
        max-width: 500px;
    }

    .registerContainer-right {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .registerContent {
        width: 80vw;
    }

    .password {
        width: 80vw;
    }
}