.historyTimeline-popup {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: #7F976C;
    color: white;
    border-radius: 5px;
    width: min-content;
    white-space: nowrap;
    padding-left: 0.5ch;
    padding-right: 0.5ch;
    z-index: 1;
    /* left: 50%;
    transform: translate(-50%) */
}

.historyTimeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 12px;
    margin-bottom: 64px;
}

.historyTimeline-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: default;
    width: 100%;
    overflow-x: auto;
}

.historyTimeline-container::before, .historyTimeline-container::after {
    content: '';
    /* Insert pseudo-element */
    margin: auto;
    /* Make it push flex items to the center */
}

.historyTimeline-year-container {
    height: 100px;
}

.historyTimeline-year-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.historyTimeline-year-items {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.historyTimeline-year-icon {
    border: 2px solid black;
    border-radius: 50%;
}

.historyTimeline-year-line {
    width: 2px;
    height: 50px;
    background-color: black;
}

.historyTimeline-year-base {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 100px;
    margin-left: 2px;
    margin-right: 2px;
    background-color: orange;
}

.important {
    background-color: red;
}

@media screen and (max-width: 600px) {
    .historyTimeline {
        margin-bottom: unset;
    }

    .historyTimeline-container {
        justify-content: unset;
    }
}