/* @import url('~react-agenda/build/styles.css'); */
@import url('~react-datetime/css/react-datetime.css');

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

a:-webkit-any-link {
  text-decoration: none;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

textarea {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.MuiTabs-indicator {
  background-color: #7F976C;
}

.MuiTab-root.Mui-selected {
  color: #7F976C;
  font-weight: bold;
}

.MuiOutlinedInput-root {
  border-color: #7F976C;
}

.MuiInputBase-root::after {
  border-bottom: 2px solid #7F976C !important;
}

.MuiStepConnector-line {
  background-color: red;
  height: 10px;
  margin-top: -5px;
}

.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #7F976C
}

.MuiSwitch-switchBase.Mui-checked {
  color: #7F976C
}

.MuiFormLabel-root.Mui-focused {
  color: black !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}

.copyright {
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  bottom: 15px;
}

.developpement-container {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 100%;
}

.focus-bg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
}

.snackbar-button {
  background: none;
  cursor: pointer;
  border: none;
}