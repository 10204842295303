.resetContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.resetContainer-left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 40vw;
    height: 100vh;
}

.resetContent {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    height: 60vh;
    width: 30vw;
}

.resetBox {
    display: flex;
    flex-direction: column;
    gap: 36px;
    width: 100%;
}

.resetBoxExpired {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 36px;
    font-style: italic;
    text-align: center;
}

.resetContainer-right {
    width: 60vw;
    height: 100vh;
}

.title {
    font-size: 48px;
    font-weight: bold;
    text-align: center;
}

.password {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 30vw;
}

.forget-password {
    font-size: 14px;
    font-weight: bold;
}

.resetButton {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: bold;
    background-color: #7F976C;
    border-radius: 50px;
    border: none;
    outline: none;
    width: fit-content;
    padding: 0 24px 0 24px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resetButton:hover {
    background-color: #8EA37E;
    cursor: pointer;
}

.resetButton:active {
    background-color: #8EA37E;
}

.cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.cta-icon {
    font-size: 18px;
    font-weight: bold;
}

.picture {
    width: 60vw;
    height: 100vh;
}

@media screen and (max-width: 1200px) {
    .resetContainer-left {
        width: 100vw;
    }
    .resetContent {
        width: 60vw;
        max-width: 500px;
    }
    .password {
        width: 60vw;
        max-width: 500px;
    }
    .resetContainer-right {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .resetContent {
        width: 80vw;
    }
    .password {
        width: 80vw;
    }
    .resetBox {
        gap: 18px;
    }
}