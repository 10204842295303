.agendCtrls-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  padding: 32px 32px 0px 32px;
}

.agendCtrls-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 0px 32px 32px 32px;
}

.agendCtrls-timePicker-wrapper {
  display: flex;
  flex-direction: row;
}

.agendCtrls-label-wrapper {
  display: flex;
}

.agendCtrls-radio-buttons {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  margin-left: 8px;
}

.agendCtrls-radio-button {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  border-radius: 50%;

}

.agendCtrls-radio-button--checked {
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  background: none;
  border: none;
  border-radius: 50%;
}

.agendCtrls-radio-button:hover {
  cursor: pointer;
}

.agendCtrls-radio-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 16px
}

.agendCtrls-time-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.agendCtrls-time-picker label {
  font-size: 15px;
  font-weight: 600;
  padding: 8px;
}

.agendCtrls-inline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-width: 250px;
}

.agendCtrls-inline label {
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 8px;
}

.agendCtrls-input {
  background-color: #7F976C;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 150px;
  height: 40px;
  margin-left: auto;
}

.agendCtrls-input:hover {
  background-color: #8EA37E;
  cursor: pointer;
}

.agendCtrls-remove {
  color: rgba(0, 0, 0, 0.4);
  width: 32px;
  height: 32px;
  margin-left: auto;
}

.agendCtrls-remove:hover {
  color: red;
  cursor: pointer;
}

.agendCtrls-closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 20px;
  height: 20px;
  color: black;
}

.agendCtrls-closeButton:hover {
  color: #7F976C;
}

.agendCtrls-backButton {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 20px;
  height: 20px;
  color: black;
}

.agendCtrls-backButton:hover {
  color: #7F976C;
}

.agendCtrls-fields div {
  width: 100%;
  font-size: 15px;
  font-weight: 600;
}

.agendCtrls-fields div span {
  font-weight: 400;
}

.agendCtrls-fields div#center {
  text-align: center;
}

.agendCtrls-fields div a {
  display: block;
  width: 100%;
  font-size: 12px;
  color: #7F976C;
  text-align: end;
}

@media screen and (max-width: 639px) {
  .agendCtrls-timePicker-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: column;
    padding-top: 8px;
  }

  .agendCtrls-inline {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

}

@media screen and (max-width: 1000px) {
  .agendCtrls-wrapper {
    flex-direction: column-reverse;
    gap: 32px;
  }

}