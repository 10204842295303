.adviceCardContainer {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #EDEDED;
    outline: 1px solid #7F976C;
    border-radius: 5px;
}

.adviceCardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
}

.adviceCardTitle {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.adviceCardDescription {
    width: 95%;
    height: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    white-space: pre-line;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.adviceCardButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: transparent;
    border-top: 1px solid #7F976C;
    border-radius: 0 0 5px 5px;
    cursor: pointer;
    width: 100%;
}

.adviceCardButton:hover {
    background-color: rgba(0, 0, 0, 0.05);
}