.schema {
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

#schema_patient.schema {
    justify-content: space-between;
    height: 100%;
    padding: 24px 0;
}

#schema_consultation.schema {
    justify-content: center;
    height: 90%;
}

.schema-line {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 200px;
}

.schema-square {
    width: 50px;
    height: 50px;
    border: 2px solid black;
    border-radius: 5px;
}

.schema-square:hover {
    border: 2px solid #8EA37E;
    cursor: pointer;
}

.selected {
    border: 2px solid #7F976C !important;
}

.worked {
    border: 2px solid orange;
}

.schema-picture {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.schema-svg {
    max-height: 100%;
}

.schema-svg g:hover path {
    cursor: pointer;
    fill: rgba(255, 0, 0, 0.4)
}

.is-selected path {
    fill: rgba(255, 0, 0, 0.8)
}

.schema-svg-global {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 50%;
    object-fit: contain;
}

.schema-svg-head {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 70%;
    object-fit: contain;
}

.schema-svg-organs {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 50%;
    object-fit: contain;
}

.schema-info-hover {
    width: 20ch;
    height: 40px;
    border: 2px solid#7F976C;
    border-radius: 5px;
    background-color: #FFFFFF;
    color: #000;
    position: absolute;
    bottom: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.schema-info-clicked {
    display: none;
    border-radius: 5px;
    position: fixed;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 11;
}

.schema-info-clicked-text {
    background-color: white;
    border-radius: 5px;
    border: 2px solid #7F976C;
    color: black;
    padding: 0.5em;
}

.schema-info-clicked-buttons {
    display: flex;
    flex-direction: row;
}

.schema-info-clicked-button {
    font-size: 16px;
    margin-top: 6px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid black;
    color: black;
    background-color: #EDEDED;
    border-radius: 4px;
    text-transform: capitalize;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.schema-info-clicked-button:hover {
    color: #7F976C;
    border-color: #7F976C;
}

.global-picture {
    background: center / contain no-repeat url("../../Assets/global.svg");
}

.head-picture {
    background: center / contain no-repeat url("../../Assets/head.svg");
}

.organs-picture {
    background: center / contain no-repeat url("../../Assets/organs.svg");
}

@media screen and (max-width: 900px) {
    .schema {
        position: relative;
        width: 100%;
    }

    .schema-line {
        position: absolute;
        top: 80px;
        right: 0;
    }
}

@media screen and (max-width: 600px) {
    .schema-svg {
        margin-top: 10px;
    }
}