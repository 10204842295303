.patient-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 6px 24px
}

.patient-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1ch;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
}

.patient-infos {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 45%;
    height: 100%
}

.patient-infos-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
}

.patient-button {
    font-weight: bold;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    align-self: center;
}

.patient-button.create {
    background-color: #7F976C;
}

.patient-button.create:hover {
    background-color: #8EA37E;
}

.patient-button.delete {
    background-color: #ff0000;
}

.patient-button.delete:hover {
    background-color: #ff000080;
}

.patient-button:disabled {
    background-color: #8ea37e52;
}

@media screen and (max-width: 900px) {
    .patient-container {
        flex-direction: column;
        width: 100%;
    }

    .patient-infos {
        width: 100%;
    }

    .patient-consult {
        position: relative;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .patient-container {
        width: 100vw;
    }
}