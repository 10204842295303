.consultation-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 6px
}

.consultation-infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45%;
    height: 100%
}

.consultation-userName {
    font-style: italic;
    color: rgba(0, 0, 0, 0.5);
    padding-left: 5ch;
    line-height: 1.5
}

.consultation-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 6px;
    width: 100%;
}

.consultation-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.consultation-radio-label {
    width: 16ch;
}

.consultation-label {
    width: 20ch;
}

.consultation-space-between {
    justify-content: space-between;
}

.consultation-center {
    justify-content: center;
}

.consultation-item {
    width: 45%
}

.consultation-input {
    background-color: #7F976C;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    outline: none;
    width: 200px;
    height: 40px;
}

.consultation-input:hover {
    background-color: #8EA37E;
    cursor: pointer;
}

.desktop {
    display: flex;
}

.mobile {
    display: none;
}

@media screen and (max-width: 900px) {
    .consultation-container {
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }

    .consultation-infos {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .consultation-container {
        width: 100vw;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: flex;
    }
}