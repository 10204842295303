.adviceContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 6px
}

.adviceContent {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    width: 100%;
    height: 100%;
    gap: 16px;
    padding: 16px 10px 16px 10px
}

@media screen and (max-width: 900px) {
    .adviceContent {
        justify-items: center;
    }
}

.adviceRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 900px) {
    .adviceRow {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}

.adviceFilterField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 900px) {
    .adviceFilterField {
        width: 400px;
    }

    .adviceFilterSearchField {
        padding-left: 10px;
    }
}

.adviceModalContainer {
    width: 96%;
    height: 380px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border: 2px solid black;
    border-radius: 5px;
    z-index: 100;
    padding: 16px;
}

.adviceModalSelect {
    width: 100%;
    margin-top: 14px;
}

@media screen and (min-width: 500px) {
    .adviceModalContainer {
        width: 500px;
        height: 300px;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        border: 2px solid black;
        border-radius: 5px;
        z-index: 100;
        padding: 16px;
    }

    .adviceModalRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .adviceModalSelect {
        width: 200px;
        margin-left: 16px;
        margin-top: unset;
    }
}

.adviceModalButton {
    background-color: #7F976C;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    outline: none;
    width: 150px;
    height: 40px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.adviceModalButtonDelete {
    color: red;
    position: absolute;
    right: 16px;
    bottom: 16px;
}

.adviceModalButton:hover {
    background-color: #8EA37E;
}

.MuiCheckbox-root.Mui-checked {
    color: #7F976C;
}