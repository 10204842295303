.settings-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.settings-content {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
}

.settings-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 24px
}

.settings-logo {
    border: 2px solid black;
    border-radius: 10px;
    padding: 6px;
    width: 150px;
    height: 150px;
    position: relative;
    display: flex;
    justify-content: center;
}

.settings-logo img {
    max-height: 100%;
    max-width: 100%;
    object-fit: scale-down;
}

@media screen and (max-width: 1100px) {
    .settings-content {
        width: 60%;
    }
}

@media screen and (max-width: 800px) {
    .settings-content {
        width: 80%;
    }
}


@media screen and (max-width: 500px) {
    .settings-row {
        flex-direction: column;
    }
}