.anamnesis-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%
}

.anamnesis-userName {
    font-style: italic;
    color: rgba(0, 0, 0, 0.5);
    padding-left: 5ch;
    line-height: 1.5
}

.anamnesis-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 6px;
    width: 100%;
}

.anamnesis-title {
    margin: 24px 0;
    padding: 0;
}

.anamnesis-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.anamnesis-radio-label {
    width: 16ch;
}

.anamnesis-label {
    width: 20ch;
}

.anamnesis-space-between {
    justify-content: space-between;
}

.anamnesis-center {
    justify-content: center;
}

.anamnesis-item {
    width: 45%
}

.anamnesis-input {
    background-color: #7F976C;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    outline: none;
    width: 200px;
    height: 40px;
}

.anamnesis-input:hover {
    background-color: #8EA37E;
    cursor: pointer;
}

.desktop {
    display: flex;
}

.mobile {
    display: none;
}

@media screen and (max-width: 600px) {
    .anamnesis-container {
        width: 100vw;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: flex;
    }
}