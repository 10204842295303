.patientInfo-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%
}

.patientInfo-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.patientInfo-item {
    width: 45%
}

.history-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.history-panel .MuiOutlinedInput-root {
    margin-bottom: 10px;
}

.history-panel-consults {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.patientInfo-file-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 6px;
    height: 42px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}

.patientInfo-file-container:hover {
    border-color: black;
    font-weight: bolder;
    cursor: pointer;
}

.patientInfo-file-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    width: 100%;
}