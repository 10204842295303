.patients-input {
    background-color: #7F976C;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    outline: none;
    width: 150px;
    height: 40px;
}

.patients-input:hover {
    background-color: #8EA37E;
    cursor: pointer;
}

.patients-closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 20px;
    height: 20px;
    color: black;
}

.MuiTableRow-hover {
    cursor: pointer;
}