.historyField-title {
    transform: translate(-4px, -14px) scale(0.75);
    font-size: 17px;
    color: rgba(0, 0, 0, 0.54);
    position: absolute;
    background-color: white;
    padding-left: 4px;
    padding-right: 4px;
}

.historyField-container {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    padding-top: 24px;
    margin-bottom: 23px;
}

.historyField-container:hover {
    border: 1px solid rgba(0, 0, 0, 1);
}

.historyField-container p {
    font-style: italic;
    color: rgba(0, 0, 0, 0.54);
}

.historyField-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.historyField-content {
    width: 100%;
    height: 100%;
}

.historyField-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    margin: 0 10px 15px 10px;
}