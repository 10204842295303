.edit-item-icon {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYgMzQuNXY3LjVoNy41bDIyLjEzLTIyLjEzLTcuNS03LjUtMjIuMTMgMjIuMTN6bTM1LjQxLTIwLjQxYy43OC0uNzguNzgtMi4wNSAwLTIuODNsLTQuNjctNC42N2MtLjc4LS43OC0yLjA1LS43OC0yLjgzIDBsLTMuNjYgMy42NiA3LjUgNy41IDMuNjYtMy42NnoiLz48cGF0aCBkPSJNMCAwaDQ4djQ4aC00OHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=) no-repeat;
  background-size: cover;
  width: 12px;
  height: 12px;
}

.edit-patient-icon {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDlweCIgaGVpZ2h0PSI0OXB4IiB2aWV3Qm94PSIwIDAgNDkgNDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yNC41IDI1Ljk4QzI5LjQ3MjUgMjUuOTggMzMuNSAyMS45MjI3IDMzLjUgMTYuOTEzM0MzMy41IDExLjkwNCAyOS40NzI1IDcuODQ2NjggMjQuNSA3Ljg0NjY4QzE5LjUyNzUgNy44NDY2OCAxNS41IDExLjkwNCAxNS41IDE2LjkxMzNDMTUuNSAyMS45MjI3IDE5LjUyNzUgMjUuOTggMjQuNSAyNS45OFoiIGZpbGw9IiMzMzMzMzMiLz4KPHBhdGggZD0iTTE3LjM3ODYgMjkuMjAyMUMxNy44ODE0IDI5LjA3ODUgMTguMzk3MSAyOS4zMzEzIDE4LjYyNTQgMjkuNzk5MUwyMi42ODkzIDMyLjc5OTRDMjQuNSAzMi43OTkgMjQuNSAzMi43OTk1IDI2LjMxMDUgMzIuNzk5MUwzMC4zNzQ2IDI5Ljc5OTFDMzAuNjAyOSAyOS4zMzEzIDMxLjExODYgMjkuMDc4NSAzMS42MjE0IDI5LjIwMjFDMzcuMDI1NSAzMC41MzAxIDQyLjUgMzMuMjM4IDQyLjUgMzcuMzExNlY0MS44NDVINi41VjM3LjMxMTZDNi41IDMzLjIzOCAxMS45NzQ1IDMwLjUzMDEgMTcuMzc4NiAyOS4yMDIxWiIgZmlsbD0iIzMzMzMzMyIvPgo8L3N2Zz4K) no-repeat;
  background-size: cover;
  width: 12px;
  height: 12px;
}

.remove-item-icon {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI0IDRjLTExLjA1IDAtMjAgOC45NS0yMCAyMHM4Ljk1IDIwIDIwIDIwIDIwLTguOTUgMjAtMjAtOC45NS0yMC0yMC0yMHptMTAgMjcuMTdsLTIuODMgMi44My03LjE3LTcuMTctNy4xNyA3LjE3LTIuODMtMi44MyA3LjE3LTcuMTctNy4xNy03LjE3IDIuODMtMi44MyA3LjE3IDcuMTcgNy4xNy03LjE3IDIuODMgMi44My03LjE3IDcuMTcgNy4xNyA3LjE3eiIvPjxwYXRoIGQ9Ik0wIDBoNDh2NDhoLTQ4eiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==) no-repeat;
  background-size: cover;
  width: 12px;
  height: 12px;
}

.agenda-cell-item {
  font-size: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
  position: absolute;
  /* background: rgba(251, 251, 251, 1); */
  margin: -2px;
  line-height: 1.2;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0 2px 6px;
  border: 1px solid rgba(0, 0, 0, 0.4); */
  transition: width .3s;
  transition-timing-function: ease-in-out;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.agenda-item-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 12px;
}

.agenda-item-description section {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.agenda-item-description small {
  color: #ff0000;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

.agenda-controls-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}

.agenda-edit-event {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 50%;
  border-right: 1px solid #eee;
}

.agenda-edit-modele {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.agenda-patient-event {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 50%;
  border-right: 1px solid #eee;
}

.agenda-patient-modele {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.agenda-delete-event {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 50%;

}

.agenda-delete-modele {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}