.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh
}

.loading-color {
  color: #7F976C;
}

.control-buttons {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .control-buttons {
    width: 100%;
    justify-content: space-between;
  }
}

.button-control {
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid black;
  color: black;
  background-color: #EDEDED;
  border-radius: 4px;
  text-transform: capitalize;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.button-control:hover {
  color: #7F976C;
  border-color: #7F976C;
}